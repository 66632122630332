$icon-font-path: "/fonts/bootstrap/";
$fa-font-path:   "/fonts/fontawesome";
.logo-main{
  display: block;
  margin: 0px auto 20px;
  max-width: 100%;
}
body {
  padding-top: 25px;
  color: rgb(201, 202, 145);
  line-height: 1;
  }
.row {
  margin-left: 15px;
  margin-right: 15px;
}
.jumbotron{
  background-color: transparent;
  color: rgb(201, 202, 145);
}
.well{
  padding: 5px;
  background-color: transparent;
  border: none;
}
.navbar{
  margin-bottom: 0px;
}

.sidebar-block{
  background: url(/images/sidebar_bg.jpg) no-repeat center bottom;
  padding: 20px 30px 40px;
  color: #000 !important;
  text-shadow: 0 1px 2px #C29C55 !important;
}
.sidebar-header{
    text-shadow: 0 1px 2px #C29C55;
    font: bold 16px "Palatino Linotype","Times",serif;
    color: rgba(0, 0, 0, 0.9);
    background: url(/images/sidebar_top.png) no-repeat center top;
    margin: -20px -30px 0px;
    padding: 40px 50px 20px;
    text-align: center;
}
div.most-popular-guides li.download-total {
  border-bottom: 1px dotted #333;

}
div.most-popular-guides li {
  list-style: none outside none;
  clear: both;
  float: left;
}
div.most-popular-guides li.screenshot {
  min-height: 80px;
}
div.most-popular-guides li.title {
  margin-bottom: 5px;
  width: 210px;
}
div.most-popular-guides img.screenshot {
  max-height: 80px;
  max-width: 120px;
}
.content{
  background-color: rgba(25, 24, 20, 1);
  padding: 10px;
}
.footer{
  background-color: rgba(25, 24, 20, 1);
}
.container{
  border: 20px solid black;
  border-image-source: url(/images/border.png);
  border-image-slice: 20 22;
  border-image-repeat: round;
  padding-right: 0px;
  padding-left: 0px;
}
#login-nav input { margin-bottom: 15px; }